import axiosIns from "@/libs/axios";

const get = async ({tipo_transaccion, sede_id, fecha, usuario_id}) => {
  return await axiosIns.get(`transacciones?tipo_transaccion=${tipo_transaccion}&sede_id=${sede_id}&fecha=${fecha}&usuario_id=${usuario_id}`)
}

const getVendedor = async ({tipo_transaccion, sede_id, fecha, usuario_id}) => {
  return await axiosIns.get(`transacciones/vendedor?tipo_transaccion=${tipo_transaccion}&sede_id=${sede_id}&fecha=${fecha}&usuario_id=${usuario_id}`)
}

const store = async (data) => {
  return await axiosIns.post('transacciones', data)
}

const update = async (id, data) => {
  return await axiosIns.put(`transacciones/${id}`, data)
}

const destroy = async (id) => {
    return await axiosIns.delete(`transacciones/${id}`)
}

const findOne = async (id) => {
  return await axiosIns.get(`transacciones/${id}`)
}

const findOnePdf = async (id) => {
  return await axiosIns.get(`transacciones/pdf/${id}`)
}

const findOneVentaCostosPdf = async (id) => {
  return await axiosIns.get(`transacciones/pdf/costo/${id}`)
}

const findPromocinesDisponibles = async (id) => {
  return await axiosIns.get(`promociones/disponibles/${id}`)
}

const findAllProductos = async (sede_id, promo_id) =>
  await axiosIns.get(
    `promociones/productos?sede_id=${sede_id}&promo_id=${promo_id}`
  )

export default {
  get,
  store,
  update,
  destroy,
  findOnePdf,
  findOne,
  getVendedor,
  findOneVentaCostosPdf,
  findPromocinesDisponibles,
  findAllProductos
}
