<template>
  <div>
    <b-card title="Gestion de Ventas">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                v-if="$hascan('venta-registrar')"
                @click="$router.push('/ventas/punto-venta')"
              >
                <feather-icon icon="SaveIcon" class="mr-50" />
                <span class="align-middle">NUEVA VENTA</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="verPdf(item.id)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span class="align-middle">VER VENTA</span>
                </span>
                <feather-icon icon="EyeIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('venta-ver_reporte_costo')"
                @click="verVentaCostosPdf(item.id)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span class="align-middle">VER COSTOS VENTA</span>
                </span>
                <feather-icon icon="EyeIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('venta-anular')"
                @click="confirmarEliminar(item)"
                :disabled="
                  buttonDisabled || item.estado != 1 || item.factura_id
                    ? true
                    : false
                "
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">ANULAR</span>
                </span>
                <feather-icon icon="XIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('venta-generar_factura')"
                @click="generarFactura(item)"
                :disabled="buttonDisabled || item.factura_id ? true : false"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="SmartphoneIcon" class="mr-50" />
                  <span class="align-middle">REALIZAR FACTURA</span>
                </span>
                <feather-icon
                  icon="SmartphoneIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="mt-1 mt-md-0">
            <b-row>
              <b-col cols="12" md="5">
                <b-form-group class="">
                  <v-select
                    id="usuario"
                    v-model="usuario_id"
                    label="nombre"
                    class="style-chooser select-size-sm"
                    placeholder="Buscar Usuario Por Nombre"
                    :filterable="false"
                    :clearable="true"
                    :options="usuarios"
                    :key="usuario_id"
                    :reduce="(opcion) => opcion.id"
                    @search="buscarUsuarios"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search">
                        Lo siento, no hay opciones de coincidencia
                      </span>
                      <span v-else>Digite para buscar</span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" class="mt-1 mt-md-0">
                <b-form-group class="">
                  <b-form-datepicker
                    v-model="fecha"
                    placeholder="YYYY-MM-DD"
                    size="sm"
                    class="form-control"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    @input="listar"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" class="mt-1 mt-md-0">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          tipo="vistaAdmin"
          :items="items"
          :loading="loading"
          @selectItemVenta="selectItemVenta"
        />
      </b-card-text>
    </b-card>

    <ModalPdfTransaccion
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :accionModalPdf="accionModalPdf"
      :esFactura="true"
      @cerrarModalPdf="cerrarModalPdf"
    />
    <GenerarFactura ref="generarFactura" @listar="listar" />
  </div>
</template>
  
<script>
import VentasMixin from "../mixins/index";
import UsuarioServices from "@/modules/configuracion/usuario/services/index.js";
import VentaServices from "../services/index";
import vSelect from "vue-select";

export default {
  name: "ListaVentasLayout",
  mixins: [VentasMixin],
  components: {
    vSelect,
  },
  data() {
    return {
      usuarios: [],
    };
  },
  watch: {
    usuario_id() {
      this.listar();
    },
  },
  methods: {
    async listarService(params) {
      return VentaServices.get(params);
    },
    buscarUsuarios(search, loading) {
      if (search.length) {
        clearTimeout(this.retraso);
        this.retraso = setTimeout(async () => {
          loading(true);
          try {
            const response = await UsuarioServices.getUsuarioPorSede({
              sede_id: this.mixing.sede_id,
              q: search,
            });

            this.usuarios = response.data;
          } catch (error) {
            console.log(error);
          } finally {
            loading(false);
          }
        }, 350);
      } else {
        this.usuarios = [];
      }
    },
  },
  mounted() {
    this.listar();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
  