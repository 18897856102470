<template>
  <div>
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <ModalPdf
      :pdf="pdf"
      :urlSiat="urlSiat"
      :facturaId="facturaId"
      :pdfBase64="pdfBase64"
      :accionModalPdf="accionModalPdf"
      @cerrarModalPdf="cerrarModalPdf"
    />
  </div>
</template>
  <script>
import VentaServices from "../services/index";
import { BOverlay } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ModalPdf from "@/modules/components/ModalPdf.vue";
import FacturacionServices from "@/modules/configuracion-sin/services/index";
export default {
  components: {
    BOverlay,
    ModalPdf,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      factura: {},
      submitted: false,
      accionModalPdf: false,
      pdf: "",
      urlSiat: "",
      pdfBase64: "",
      facturaId: "",
      id: null,
      factura_id: null,
      numero_factura: null,
    };
  },
  props: [],
  methods: {
    async realizarFactura(item) {
      this.submitted = true;
      let enviarCorreoElectronico = false;
      if (item.cliente.correo_electronico) {
        enviarCorreoElectronico = true;
      }
      let detalleTransaccion = [];
      for (let i = 0; i < item.detalle_transaccion.length; i++) {
        if (
          item.detalle_transaccion[i].producto.codigo_actividad != "" &&
          item.detalle_transaccion[i].producto.codigo_sin != null
        ) {
          detalleTransaccion.push({
            id: item.detalle_transaccion[i].producto_id,
            actividadEconomica:
              item.detalle_transaccion[i].producto.codigo_actividad,
            codigoProductoSin: item.detalle_transaccion[i].producto.codigo_sin,
            codigoProducto: item.detalle_transaccion[i].producto.codigo,
            descripcion: item.detalle_transaccion[i].producto.descripcion,
            cantidad: parseFloat(item.detalle_transaccion[i].cantidad),
            unidadMedida: item.detalle_transaccion[i].producto.unidad_id,
            precioUnitario: item.detalle_transaccion[i].precio,
            montoDescuento: item.detalle_transaccion[i].descuento,
            numeroSerie: item.detalle_transaccion[i].numeroSerie,
            numeroImei: item.detalle_transaccion[i].numeroImei,
            subTotal: item.detalle_transaccion[i].subtotal,
          });
        } else {
          this.$bvToast.toast(
            "Falta el código de actividad y/o el código SIN",
            {
              title: "ERROR AL GENERAR LA FACTURA!",
              variant: "danger",
              solid: false,
            }
          );
          this.submitted = false;
          return;
        }
      }

      this.factura = {
        cafc: null,
        codigoTipoDocumentoIdentidad: item.cliente.tipo_documento_id,
        numeroDocumento: item.numero_documento,
        codigoCliente: item.cliente_proveedor_id,
        nombreRazonSocial: item.razon_social,
        complemento: item.cliente.complemento ? item.cliente.complemento : null,
        codigoDocumentoSector: 1,
        codigoExcepcion: item.cliente.es_verificado ? 0 : 1,
        codigoMetodoPago: item.metodo_pago_id,
        codigoMoneda: 1,
        codigoPuntoVenta: 0,
        codigoSucursal: 0,
        descuentoAdicional: item.descuento,
        detalles: detalleTransaccion,
        email: item.cliente.correo_electronico,
        fechaHora: null,
        leyenda: "",
        montoGiftCard: "0.00",
        montoTotalSujetoIva: item.total,
        montoTotal: item.total,
        montoTotalMoneda: item.total,
        numeroDocumentoCliente: item.numero_documento,
        numeroFactura: 0,
        numeroTarjeta: item.nro_tarjeta,
        online: true,
        tipoCambio: 1,
        tipoEmision: 1,
        tipoFactura: 1,
        usuario: item.usuario.nombre,
        subTotal: item.subtotal,
        enviarCorreo: enviarCorreoElectronico,
        documentoOrigen: item.id,
        tamanio: 1,
      };
      try {
        await this.registrar();
        if (this.factura_id && this.numero_factura) {
          await this.actualizar(item.id, {
            factura_id: this.factura_id,
            numero_factura: this.numero_factura,
          });
        }
      } catch (err) {
        this.submitted = false;
        this.$bvToast.toast(" ERROR AL GENERAR LA FACTURA", {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async registrar() {
      try {
        const response = await FacturacionServices.store(this.factura);
        console.log(response.data);
        this.submitted = false;
        this.$bvToast.toast(" LA FACTURA SE GENERO CORRECTAMENTE. ", {
          title: "EXITO!",
          variant: "success",
          solid: false,
        });
        this.numero_factura = response.data.data.venta.nroFactura;
        this.factura_id = response.data.data.venta.id;
        this.pdfBase64 = response.data.data.facturaBase64;
        this.pdf =
          "data:application/pdf;base64," +
          response.data.data.facturaBase64 +
          "#toolbar=0";
        this.accionModalPdf = true;
        this.submitted = false;
        this.urlSiat = response.data.data.venta.urlSiat;
        this.facturaId = response.data.data.venta.id;
      } catch (err) {
        this.submitted = false;
        this.numero_factura = null;
        this.factura_id = null;
        const data = err.response.data;
        const mensaje = data.mensaje;
        const errors = data.errors;
        let detalle = "";
        if (mensaje || data.detalles) {
          detalle = `Error ${mensaje}`;
        } else if (errors) {
          const h = this.$createElement;
          detalle = Object.entries(errors).map(([key, value]) => {
            return h("p", { class: ["mb-0"] }, [
              h("strong", key + ":"),
              " " + value,
              h("br"),
            ]);
          });
        } else {
          detalle = `${data[0].codigo} - ${data[0].descripcion}`;
        }
        this.$bvToast.toast(detalle, {
          title: "ERROR!",
          autoHideDelay: 7000,
          variant: "danger",
          toaster: "b-toaster-top-full",
          solid: false,
          html: true,
        });
      }
    },
    async actualizar(id, data) {
      try {
        await VentaServices.update(id, data);
        this.$emit("listar");
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.urlSiat = "";
      this.facturaId = "";
      this.pdfBase64 = "";
    },
  },
  mounted() {},
};
</script>
  <style lang="scss">
</style>