<template>
  <b-modal
    id="modal-pdf"
    ref="modal"
    title="FACTURA"
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
    scrollable
    body-bg-variant="dark"
    body-text-variant="light"
    ok-title="Verificar en SIAF"
    cancel-title="Renviar Factura"
    button-size="sm"
    @cancel.prevent="renviarFactura"
    @ok.prevent="irSiaf"
    v-model="accionModal"
    ok-only
    @close="cerrarModal"
  >
    <vue-pdf-app
      v-show="pdf"
      :page-number="1"
      class="height-pdf"
      :pdf="pdf"
      file-name="FACTURA"
      :config="config"
      @pages-rendered="pagesRenderedHandler"
    >
      <template #toolbar-right-prepend="{}">
        <button
          @click="printPdf"
          type="button"
          title="Imprimir"
          style="padding: 8px 6px 0; font-size: 12px"
          class="prepend print hiddenMediumView vue-pdf-app-icon print-button"
        ></button>
      </template>
      <template #secondary-toolbar-prepend="{}">
        <button
          title="Imprimir"
          type="button"
          class="secondaryToolbarButton print vue-pdf-app-icon print-button"
          @click="printPdf"
        >
          <span>Imprimir</span>
        </button>
      </template>
    </vue-pdf-app>
  </b-modal>
</template>
<script>
import axios from "axios";
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "vue-pdf-app/dist/icons/main.css";
import printJS from "print-js";

export default {
  components: {
    BModal,
  },
  props: ["accionModalPdf", "pdf", "urlSiat", "facturaId", "pdfBase64"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      config: {
        sidebar: false,
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            viewBookmark: false,
          },
        },
        secondaryToolbar: {
          secondaryPresentationMode: false,
          secondaryOpenFile: false,
          secondaryPrint: false,
          secondaryViewBookmark: false,
          firstPage: false,
          lastPage: false,
          pageRotateCw: false,
          pageRotateCcw: false,
          cursorSelectTool: false,
          cursorHandTool: false,
          scrollVertical: false,
          scrollHorizontal: false,
          scrollWrapped: false,
          spreadNone: false,
          spreadOdd: false,
          spreadEven: false,
        },
      },
      accionModal: false,
    };
  },
  watch: {
    accionModalPdf: function () {
      if (this.accionModalPdf) {
        this.accionModal = true;
      }
    },
  },
  methods: {
    cerrarModal() {
      this.$emit("cerrarModalPdf");
    },
    printPdf() {
      console.log("entro", this.pdfBase64);
      printJS({
        printable: this.pdfBase64,
        type: "pdf",
        base64: true,
      });
    },
    pagesRenderedHandler(pdfApp) {
      // setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = "page-width"));
    },
    async renviarFactura() {
      await axios
        .post(
          `${process.env.VUE_APP_FACT_REST}/facturacion/email/reenviar`,
          {
            facturaID: this.facturaId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              apikey: `${process.env.VUE_APP_APIKEY}`,
            },
          }
        )
        .then((res) => {
          this.$bvToast.toast(res.data, {
            title: "Exito!",
            variant: "primary",
            solid: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    irSiaf() {
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = this.urlSiat;
      a.click();
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.height-pdf {
  height: calc(var(--vh, 1vh) * 100 - 12rem);
  .pdfViewer .page > * {
    height: 100% !important;
  }
}
#modal-pdf {
  .modal-xl {
    margin-left: 25%;
    margin-right: 25%;
  }
  .modal-body {
    padding: 0.5rem 0.5rem;
  }
}
</style>