<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham mb-1"
        :class="
          tipo == 'vistaAdmin' ? 'ag-grid-table' : 'ag-grid-table-vendedor'
        "
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="lista"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @selection-changed="onSelectionChanged"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
        :pinnedBottomRowData="pinnedBottomRowData"
      >
      </ag-grid-vue>
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                items.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : lista.length
              }}
              de {{ lista.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import {
  BCard,
  BCardText,
  BOverlay,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    BOverlay,
    BPagination,
    BRow,
    BCol,
  },
  props: ["items", "loading", "tipo"],
  data() {
    return {
      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        //editable: false,
        floatingFilter: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "COMPROBANTE",
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            }
            return param.data.comprobante + "-" + param.data.numero_comprobante;
          },
          // field: 'numeroFactura',
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "METODO DE PAGO",
          field: "metodo_pagos.descripcion",
          filter: false,
          minWidth: 200,
          // maxWidth: 120,
        },
        {
          headerName: "FECHA",
          field: "created_at",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "DOCUMENTO",
          field: "numero_documento",
          filter: true,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "RAZON SOCIAL",
          field: "razon_social",
          filter: true,
          minWidth: 250,
          // maxWidth: 300,
        },
        {
          headerName: "SUBTOTAL",
          field: "subtotal",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "DESCUENTO",
          field: "descuento",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "TOTAL",
          field: "total",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "CAJERO",
          field: "usuario.nombre",
          filter: true,
          minWidth: 200,
          // maxWidth: 200,
        },
        {
          headerName: "OBSERVACIONES",
          field: "observaciones",
          filter: false,
          minWidth: 300,
          maxWidth: 300,
        },
        {
          headerName: "ESTADO",
          filter: false,
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            }
            if (param.data.estado == 1) {
              return "ACTIVO";
            } else if (param.data.estado == 2) {
              return "FACTURADO";
            } else {
              return "ANULADO";
            }
          },
          minWidth: 100,
          maxWidth: 100,
        },
      ],
      rowClassRules: { "row-red": "data.estado === 0" },
      searchQuery: "",
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    pinnedBottomRowData() {
      return [
        {
          total: this.total,
        },
      ];
    },
    lista() {
      return this.items && this.items[0] ? this.items[0] : [];
    },
    total() {
      return this.items && this.items[1] ? this.items[1].total : 0;
    },
  },
  created() {},
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemVenta", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemVenta", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
function numberFormatter(params) {
  if (params.node.rowPinned) {
    if (params.value == null) {
      return undefined;
    }
    return Number(params.value).toFixed(2);
  } else {
    return Number(params.value).toFixed(2);
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
</style>
