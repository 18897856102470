import axios from "axios";
import storeas from "../../../store/index.js";
const { api_key: API_KEY } = storeas.getters["auth/authenticated"].config;

// CATALOGOS
const getCatalogos = async (param) => {
  return await axios.get(`${process.env.VUE_APP_FACT_REST}/facturacion/catalogos/${param}`,{
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  })
}

// FACTURACION
const get = async (sucursal, puntoVenta, documentoSector, data) => {
  return await axios.get(`${process.env.VUE_APP_FACT_REST}/facturacion/facturas/sucursal/${sucursal}/codigoPv/${puntoVenta}/documentoSector/${documentoSector}`, {
    params: data,
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  });
};

const store = async (data) => {
  return await axios.post(`${process.env.VUE_APP_FACT_REST}/facturacion/facturas`, data, {
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  })
}

const findOne = async (id) => {
  return await axios.get(`${process.env.VUE_APP_FACT_REST}/facturacion/facturas/${id}`,{
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  })
}

const verificar = async (id) => {
  return await axios.get(`${process.env.VUE_APP_FACT_REST}/facturacion/facturas/verificar/${id}`,{
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  })
}

const anular = async (data) => {
  return await axios.post(`${process.env.VUE_APP_FACT_REST}/facturacion/facturas/anular`, data, {
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  })
}



// CODIGOS CONTROLLER 
const sincronizarCatalogo = async () => {
  return await axios.get(`${process.env.VUE_APP_FACT_REST}/facturacion/sincronizacion/catalogo`,{
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  })
}
const sincronizarFechaHora = async () => {
  return await axios.get(`${process.env.VUE_APP_FACT_REST}/facturacion/productosservicios`,{
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  })
}
const verficarNit = async (nit) => {
  return await axios.get(`${process.env.VUE_APP_FACT_REST}/facturacion/verificarnit/${nit}`,{
    headers: {
      "Content-Type": "application/json",
      apikey: `${API_KEY}`,
    },
  })
}
export default {
  // CATALOGOS
  getCatalogos,

  // FACTURACION
  get,
  store,
  findOne,
  verificar,
  anular,

  // SYNC CODIGOS CONTROLLER
  sincronizarCatalogo,
  sincronizarFechaHora,
  verficarNit
}
